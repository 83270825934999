import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "FIZ A MELHOR ESCOLHA DA MINHA VIDA, MINHA MOTIVAÇÃO VOLTOU E ESTOU VENDO RESULTADOS!",
    name: 'MATEUS ENDRIK',
    status : 'EMPRESÁRIO'
  },
  {
    image: image2,
    review: 'EU AMO A FORMA DE TRABALHAR DESSA EMPRESA, ME SINTO A VONTADE PARA APLICAR MEU METODOS! ',
    name: 'JOÃO CRENTE',
    status: 'PERSONAL'
  },
  {
    image : image3,
    review:' JA HAVIA TENTANDO VARIOS METODOS, MAIS AGORA FOI DIFENTE, ESSE É O SIMPLES QUE DA RESULTADO DE VERDADE',
    name: 'FRANCISCO',
    status: "GARÇOM"
  }
];
