import React, { useState } from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import { Link } from 'react-scroll'

import NumberCounter from 'number-counter'

import { motion } from 'framer-motion'

const Hero = () => {
    const [menuOpened, setMenuOpened] = useState(false)
    const transition = {type: 'spring', duration : 3};
    const mobile = window.innerWidth<=768 ? true: false;
  return (
    <div className="hero" id='home'>

        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
{/* o melhor anuncio*/}

            <div className="the-best-ad">
                <motion.div
                initial={{left: mobile? "165px": '238px'}}
                whileInView={{left: '8px'}}
                transition={{...transition, type: 'tween'}}
                ></motion.div>
                <span>Os melhores resultados da cidade!</span>
            </div>

            {/* Anuncio prinpal inicio heroi*/}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Se </span>
                    <span>Transforme.</span>
                </div>
                <div>
                    <span>O plano ideal para seu corpo!</span>
                </div>
                <div>
                    <span>
                        Aqui, vamos ajudá-lo com suas metas.
                    </span>
                </div>
            </div>

            {/*figures */}
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={50} start={7} delay='15ms' preFix="+"/>
                    </span>
                    <span>Studios</span>
                </div>
                <div>
                    <span>
                        <NumberCounter end={1000} start={850} delay='0.01ms' preFix="+"/>
                    </span>
                    <span>Alunos</span>
                </div>
                <div>
                    <span>
                        <NumberCounter end={30} start={15} delay='5s' preFix="+"/>
                    </span>
                    <span>planos</span>
                </div>
            </div>

            {/*hero bottons botoes da esquerda sup*/}
            <div className="hero-buttons">
                <buttons className="btn">
                    <Link
                         onClick={()=>setMenuOpened(false)}
                         to='email-container'
                         span={true}
                         smooth={true}
                    >Faça Parte</Link>  
                </buttons>
                <buttons className="btn">
                    <Link
                         onClick={()=>setMenuOpened(false)}
                         to='Programs'
                         span={true}
                        smooth={true}
                    >Saiba mais</Link>  
                </buttons>
            </div>
        </div>
        <div className="rigth-h">
            <button className='btn'>
                <Link
                 onClick={()=>setMenuOpened(false)}
                 to='email-container'
                 span={true}
                 smooth={true}
                  >Faça Parte</Link>  
            </button>


            <motion.div 
            initial={{right: '-1rem'}}
            whileInView={{right: '4rem'}}
            transition={transition}
            className="heart-rate">
                <img src={Heart} alt="" />
                <span>Frequência</span>
                <span>116 bpm</span>
            </motion.div>

            {/*Hero images */}
            <img src={hero_image} alt="" className="hero-image" />
            <motion.img
            initial={{right: '11rem'}}
            whileInView={{right: '20rem'}}
            transition={transition}
            src={hero_image_back} alt="" className="hero-image-back" />
            {/*calorias */}
            <motion.div 
                initial={{right: '37rem'}}
                whileInView={{right: '28rem'}}
                transition={transition}
                className="calories">
                <img src={Calories} alt="" />              
                <div>
                    <span>Calorias queimadas</span>
                    <span>220 kcal</span>
                </div>
            </motion.div>


        
        </div>        
    </div>
  )
}

export default Hero